import $ from 'jquery';

/**
 * Convert: US Date -> UK Date
 * Convert: UK Date -> US Date
 */
export function dateConvert(date) {
    // clean date
    date = date.replace('/', '-').replace( '.', '-').substr(0,10);

    // check if - character exists
    if( date.indexOf('-') >= 0 ) {
        return date.split('-').reverse().join('-');
    }

    return false;
}

/**
 * Get plural or non-plural version of text
 * @param count
 * @param text
 * @param returnNum
 */
export function plural(count, text, returnNum) {
    // force to array to number
    if( $.isArray(count) ) {
        count = count.length;
    }

    // return number with txt
    if( returnNum ) {
        text = count + ' ' + text;
    }

    if( $.isNumeric(count) === false || count > 1 || count === 0 ){
        return text + 's';
    }

    return text;
}