import $ from 'jquery';

/**
 * Gets a given icon by its name from SVG sprite sheets
 * @param {string} icon SVG name
 * @returns {*|jQuery|HTMLElement}
 */
export function getSVGForIcon(icon) {
    if (typeof icon === 'string') {
        icon = [icon];
    }

    const $wrapper = $('<span>');

    $wrapper.append(...icon.map(function (icon) {
        const $svgIcon = $(`symbol#${icon}`);
        const svgIconViewbox = $svgIcon[0].getAttribute('viewBox'),
            svgIconContent = $svgIcon.children().clone();

        return $(`<svg viewBox="${svgIconViewbox}">`)
            .addClass('activity-icon')
            .append(svgIconContent);
    }))

    return $wrapper;
}

export function modalForm(modal_id) {
    const $modal = app.DOM.content.find(`#${modal_id}`);

    const $btnSubmit = $modal.find('button[data-action="ok"]');

    // prevent form submission
    $modal.find('form').on('submit', e => {
        e.preventDefault();

        $btnSubmit.trigger('click');
    });
}

/**
 * Open generic modal with js
 * @param {string} title Modal header title defaults to '' if null passed
 * @param {string} content Body content for the modal can be either text or a string of HTML
 * @todo Convert this to the new modal system when possible
 */
export function modalGeneric(title, content) {
    let $modal = $('#modal-generic');
    $modal.find('#modal-generic-title span').text(title);
    $modal.find('#modal-generic-content').html(content);

    MicroModal.show('modal-generic');
}

/**
 * Gets a given resources url from the manifest
 * @param {string} path the resources path without resources/ e.g. "js-esm/shared/common.js"
 * @returns {string} Resolved resource URL
 */
export function manifest(path) {
    let target = path.replace(/^[\/\.]+/, '');
    let fragment = '';
    if (target.includes('#')) {
        [target, fragment] = target.split('#');
    }
    if(app.OPTIONS.manifest[target]) {
        return app.OPTIONS.manifest[target] + (fragment !== '' ? '#' + fragment : '');
    }

    if (/^js\//.test(target)) {
        return '/dist/' + target;
    }

    return path;
}

export function displayErrors(res, err, returnErrorMessage) {
    // check returning xhr request needing rebase
    if(res.hasOwnProperty('responseJSON')) {
        res = res.responseJSON;
    }

    // DATA: ERROR
    if(res && $.isArray(res.errors) && res.errors.length > 0) {

        let msg = '';
        const eol = ( typeof(MicroModal) === 'undefined' || returnErrorMessage ) ? '\n' : '<br>';

        $.each(res.errors, (k,v) => {

            // highlight it in dom
            if(v.key && app.OPTIONS.form) {
                var $el = app.DOM.form_input.filter('#'+v.key).closest('p');

                if( $el.length ){
                    // error container
                    $el.addClass('highlight');

                    // error tab
                    app.FORM.getTabKey($el.closest('.tab'), true).addClass('error');
                }
            }

            // concat message
            msg += `- ${v.msg}${eol}`;
        });

        // default browser alert
        if( typeof(MicroModal) === 'undefined' || returnErrorMessage) {
            alert(msg);
            return;
        }

        // modal
        if(app.OPTIONS.form) {
            app.FORM.msg_modal(msg, 'There were errors:');
        } else {
            modalGeneric('There was an error', msg);
        }

        return;
    }

    // API OR CONNECTION ERROR
    alert('There was an error please try again');

    console.warn(res);

    if(err) {
        console.log(err);
    }
}

/**
 * Get key or value from URI
 *
 * /admin/cos/false/veh:123
 * @param {string|number} param
 * @param {boolean} getValue
 */
export function getFilterParam(param, getValue = true) {
    param = app.URI[param];

    if( !param || param.indexOf(':') <= 0 ) {
        return false;
    }

    // get first & last params
    param = param.split(':');

    if( param.length > 2 ) {
        const param_start = param[0];

        param.shift();

        const param_end = decodeURI(param.join(':'));

        param = [
            param_start,
            param_end
        ];
    }

    if( getValue ) {
        return param.pop();
    }

    return param[0];
}

export function getFilterKey(param, uriParam) {
    if( uriParam === undefined ) {
        uriParam = true;
    }

    return app.get_filter_param(param, false, uriParam);
}

export function getFilterVal(param, uriParam) {
    if( uriParam === undefined ) {
        uriParam = true;
    }

    return app.get_filter_param(param, true, uriParam);
}
